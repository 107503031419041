@import "../../../../assets/css/global/variables";


.filter_start_end_date {
  display: flex;
  align-items: center;
  gap: 21px;
  border-radius: 8px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
  padding: 0px 15px;


  input {
    border: none !important;
  }

  .form-group {
    margin-bottom: 0px !important;
  }

  .customize {

    &:hover {
      border-color: #0368FF;
      cursor: pointer;
    }


    &.active {
      &::after {
        transform: rotate(135deg);
        border-color: var(--primary-color);
      }
    }

    span {
      font-size: 14px;
      padding: 0 !important;
      margin: 0 !important;

      top: 3px;
      font-weight: 400;
    }

    input {
      background-color: transparent;
      padding: 0;
      height: 28px;

      &:hover {
        cursor: pointer;
      }
    }

    .input-group {
      width: 200px !important;

      &:first-child {
        input {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
      }


      &:last-child {
        input {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          font-size: 14px;
          min-width: 200px;
        }
      }

      .no-data {
        color: #bdbdbd;
      }

      .has-data {
        color: #202D41;
      }
    }

    &.line {
      border-right: 1px solid $input-border;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  input {
    position: relative;
    padding: 10px;
  }

  .ic__arr {
    display: none;
  }
}
