export class NumberUtil {
  static decimalAdjust(value, exp, type?) {
    return !this.isNull(value) ? parseFloat(value.toFixed(exp)) : null;
  }
  static isNull(amount?) {
    return amount === null || amount === undefined;
  }
  static revertMoneytoNumber(number, returnNull?) {
    number && (number+='');
    if (number && number.length > 0) {
      let array =number.split(',');
      number='';
      array.forEach((item) => {
        number+=item
      })
      return parseFloat(number)
    } else if (number !== 0) {
      return !returnNull ? 0 : null;
    } else {
      return 0
    }
  }
  static numberToMoney(number: any) {
    let isNegative: boolean = false;
    if (number < 0) {
      isNegative = true;
      number = number*-1;
    }
    let numberText = number +'';
    let numberPositive = numberText.split('.')[0] || '';
    let numberDecimal = numberText.split('.')[1] || '';
    let text = '';
    let position = 0;
    for (let i=numberPositive.length -1; i >= 0; i--) {
      if (position === 3) {
        text = ',' + text; 
        position = 0;
      }
      text = numberPositive.charAt(i) + text;
      position++;
    }
    if (isNegative) {
      text = '-' + text;
    }
    text = numberDecimal ? text + '.' + numberDecimal : text;
    return text;
  }
}
