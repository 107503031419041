import { environment } from "../../environments/environment";

export class Constant {
  //Header config
  public static header_config_code = "Fmarket";
  //path
  public static path_dashboard = "dashboard";
  public static path_products = "products";
  public static path_product = "product";
  public static path_product_dashboard = "product_";
  public static path_product_detail = "product_detail";
  public static path_survey = "survey";
  public static path_setting = "setting";
  public static path_knowledge = "knowledge";
  public static path_knowledge_article = "article";
  public static path_knowledge_dashboard = "knowledge_";
  public static path_withdraw_transaction = "withdraw-transaction";
  public static path_investor_transaction = "investor-transaction";
  public static path_investor_list = "investor-list";
  public static path_agency_list = "agency-list";
  public static path_organization_list = "organization-list";
  public static path_customer_list = "customer-list";
  public static path_fee_dashboard = "fee-dashboard";
  public static path_private_info = "private-info";
  public static path_home = "home";
  public static path_resource = "res";
  public static path_contact = "contact";
  public static path_about_us = "about_us";
  public static path_account = "account";
  public static path_auth = "auth";
  public static path_login = "login";
  public static path_ekyc = "ekyc";
  public static path_forgot = "forgot-password";
  public static path_profile = "profile";
  public static path_operations = "operations";
  public static path_change_pass = "change-password";
  public static path_change_email = "change-email";
  public static path_change_phone = "change-phone";
  public static path_activation = "activation";
  public static path_change = "change";
  public static path_active_success = "success";
  public static path_active_fail = "fail";
  public static path_change_mail_success = "change-mail/success";
  public static path_change_mail_fail = "change-mail/fail";
  public static path_active_reset_pass = "reset-password";
  public static path_register = "register";
  public static path_fee_list_of_organization = "fee-list-of-organization";
  public static path_fee_list_of_agency = "fee-list-of-agency";
  public static path_withdrawal_fee_list = "withdrawal-fee-list";
  public static path_withdrawal_organization_fee_list =
    "withdrawal-organization-fee-list";
  public static path_fplan = "fplan";
  public static path_financial_plan = "financial-plan";
  public static path_normal_plan = "normal-plan";
  public static path_comprehensive_plan = "comprehensive-plan";
  public static path_financial_plan_normal_list = "normal-list";
  public static path_financial_plan_comprehensive_list = "comprehensive-list";
  public static path_list = "list";
  public static path_point_setup = "point-setup";
  public static path_masterdata = "masterdata";
  public static path_import_template_file = "import_template_file";
  public static path_risk_survey = "risk-survey";
  public static path_questionaire = "questionaire";
  public static path_property_debt_questionaire = "property-debt";
  public static path_retirement_questionaire = "retirement";
  public static path_goal_questionaire = "goal";
  public static path_protection_questionaire = "protection";
  public static path_information_suggest = "information-suggest";

  //Dashboard

  //Key localStorage
  public static key_local_token = "token";
  public static key_local_version = "version";
  public static key_local_language = "language";
  public static key_local_status = "status";
  public static key_local_first_login = "firstLogin";
  public static key_local_email = "emailFmarket";
  public static key_local_user_type = "type";
  public static key_local_login_sucess = "loginSuccess";
  public static key_local_current_screen = "currentScreen";
  public static key_local_current_create = "create";
  public static key_local_common_data = "common-data";
  public static key_local_cms = "cms";
  //Data type
  public static data_type_languages = "languages";
  public static data_type_countries = "countries";

  //Error code
  public static error_force_logout = 401;
  public static error_not_connect_server = 0;
  public static error_server_error = 500;
  public static error_server_maintenance = 503;
  public static error_code = 400;

  //Success code
  public static success_code = 200;
  public static ERROR_CODE_RENEW_PASSWORD = 40027;

  //Default variable
  public static default_language = "vi_VN";
  //API key upload
  public static clientApiKey = "clientApiKey";
  public static clientApiKey_value = "FMARKET-ANGULAR";
  public static clientApiKey_value_report = "FMARKET-ANGULAR-KEY2";
  public static requestId = "requestId";
  public static fileType = "fileType";
  public static base64File = "base64File";
  public static fileTypeNormal = "FILE";
  public static fileTypePhoto = "PHOTO";
  public static applyOriginalFileName = "applyOriginalFileName";

  //API

  public static api_user_profile = "users/profile";

  // Router

  public static url_login =
    "/" + Constant.path_auth + "/" + Constant.path_login;
  public static url_forgot_password =
    "/" + Constant.path_auth + "/" + Constant.path_forgot;
  public static url_register =
    "/" + Constant.path_auth + "/" + Constant.path_register;

  public static url_home = "/" + Constant.path_home;
  public static url_products_dashboard = "/" + Constant.path_product_dashboard;
  public static url_about_us = "/" + Constant.path_about_us;
  public static url_knowledge_dashboard =
    "/" + Constant.path_knowledge_dashboard;
  public static url_contact = "/" + Constant.path_contact;

  public static url_reset_pass =
    "/" + Constant.path_activation + "/" + Constant.path_active_reset_pass;
  public static url_active_mail_success =
    "/" + Constant.path_activation + "/" + Constant.path_active_success;
  public static url_active_mail_fail =
    "/" + Constant.path_activation + "/" + Constant.path_active_fail;
  public static url_change_mail_success =
    "/" + Constant.path_activation + "/" + Constant.path_change_mail_success;
  public static url_change_mail_fail =
    "/" + Constant.path_activation + "/" + Constant.path_change_mail_fail;

  // url account
  public static url_account = "/" + Constant.path_account;
  public static url_investor_list_page =
    "/" +
    Constant.url_account +
    "/" +
    Constant.path_operations +
    "/" +
    Constant.path_investor_list;
  public static url_investor_transaction_page =
    "/" +
    Constant.url_account +
    "/" +
    Constant.path_operations +
    "/" +
    Constant.path_investor_transaction;
  public static url_agency_list_page =
    "/" +
    Constant.url_account +
    "/" +
    Constant.path_operations +
    "/" +
    Constant.path_agency_list;
  public static url_organization_list_page =
    "/" +
    Constant.url_account +
    "/" +
    Constant.path_operations +
    "/" +
    Constant.path_organization_list;
  public static url_customer_list_page =
    "/" +
    Constant.url_account +
    "/" +
    Constant.path_operations +
    "/" +
    Constant.path_customer_list;

  public static url_fee_dashboard_page =
    "/" +
    Constant.url_account +
    "/" +
    Constant.path_operations +
    "/" +
    Constant.path_fee_dashboard;
  public static url_fee_list_of_organization_page =
    Constant.url_fee_dashboard_page +
    "/" +
    Constant.path_fee_list_of_organization;
  public static url_fee_list_of_agency_page =
    Constant.url_fee_dashboard_page + "/" + Constant.path_fee_list_of_agency;
  public static url_withdraw_fee_page =
    Constant.url_fee_dashboard_page + "/" + Constant.path_withdrawal_fee_list;
  public static url_withdraw_organization_fee_page =
    Constant.url_fee_dashboard_page +
    "/" +
    Constant.path_withdrawal_organization_fee_list;

  public static url_account_plan =
    "/" + Constant.url_account + "/" + Constant.path_fplan;
  public static url_account_plan_list =
    "/" +
    Constant.url_account +
    "/" +
    Constant.path_fplan +
    "/" +
    Constant.path_list;

  public static url_financial_plan =
    "/" + Constant.url_account + "/" + Constant.path_financial_plan;

  public static url_financial_comprehensive_plan =
    Constant.url_financial_plan + "/" + Constant.path_comprehensive_plan;
  public static url_financial_comprehensive_plan_dashboard =
    Constant.url_financial_comprehensive_plan + "/" + Constant.path_dashboard;
  public static url_financial_plan_comprehensive_list =
    Constant.url_financial_comprehensive_plan_dashboard +
    "/" +
    Constant.path_list;
  public static url_financial_plan_comprehensive_masterdata =
    Constant.url_financial_comprehensive_plan_dashboard +
    "/" +
    Constant.path_masterdata;
  public static url_financial_plan_comprehensive_risk_survey =
    Constant.url_financial_plan_comprehensive_masterdata +
    "/" +
    Constant.path_risk_survey;
  public static url_financial_plan_comprehensive_import_template_file =
    Constant.url_financial_comprehensive_plan_dashboard +
    "/" +
    Constant.path_import_template_file;
  public static url_questionaire =
    Constant.url_financial_plan_comprehensive_masterdata +
    "/" +
    Constant.path_questionaire;
  public static url_retirement_questionaire =
    Constant.url_financial_plan_comprehensive_masterdata +
    "/" +
    Constant.path_retirement_questionaire;
  public static url_goal_questionaire =
    Constant.url_financial_plan_comprehensive_masterdata +
    "/" +
    Constant.path_goal_questionaire;
  public static url_protection_questionaire =
    Constant.url_financial_plan_comprehensive_masterdata +
    "/" +
    Constant.path_protection_questionaire;
  public static url_property_debt_questionaire =
    Constant.url_financial_plan_comprehensive_masterdata +
    "/" +
    Constant.path_property_debt_questionaire;
  public static url_information_suggest =
    Constant.url_financial_plan_comprehensive_masterdata +
    "/" +
    Constant.path_information_suggest;

  public static url_financial_normal_plan_dashboard =
    Constant.url_financial_plan +
    "/" +
    Constant.path_normal_plan +
    "/" +
    Constant.path_dashboard;
  public static url_financial_plan_normal_list =
    Constant.url_financial_normal_plan_dashboard + "/" + Constant.path_list;
  public static url_financial_plan_normal_point_setup =
    Constant.url_financial_normal_plan_dashboard +
    "/" +
    Constant.path_point_setup;
  public static url_financial_plan_normal_masterdata =
    Constant.url_financial_normal_plan_dashboard +
    "/" +
    Constant.path_masterdata;
  public static url_financial_plan_normal_import_template_file =
    Constant.url_financial_normal_plan_dashboard +
    "/" +
    Constant.path_import_template_file;

  public static url_change_password_page =
    "/" +
    Constant.url_account +
    "/" +
    Constant.path_profile +
    "/" +
    Constant.path_change_pass;
  public static url_change_email_page =
    "/" +
    Constant.url_account +
    "/" +
    Constant.path_profile +
    "/" +
    Constant.path_change_email;
  public static url_change_phone_page =
    "/" +
    Constant.url_account +
    "/" +
    Constant.path_profile +
    "/" +
    Constant.path_change_phone;
  public static url_private_info_page =
    "/" +
    Constant.url_account +
    "/" +
    Constant.path_profile +
    "/" +
    Constant.path_private_info;

  public static icon_url_host = "/assets/images/icon/";
  public static logo_url_host = "/assets/images/logo/";
  public static icon_503_host = "/assets/images/503/";
  public static icon_url_pre = Constant.icon_url_host + "arrow-l.png";
  public static icon_url_next = Constant.icon_url_host + "arrow-r.png";
  public static icon_url_convert = Constant.icon_url_host + "arrow.svg";
  public static icon_url_pdf = Constant.icon_url_host + "pdf.svg";
  public static icon_url_pdf_icon = Constant.icon_url_host + "pdf_icon.png";
  public static icon_url_excel = Constant.icon_url_host + "excel.svg";
  public static icon_url_xls_icon = Constant.icon_url_host + "xls_icon.png";
  public static icon_url_no_data = Constant.icon_url_host + "nodata.png";
  public static icon_url_add = Constant.icon_url_host + "add.svg";
  public static icon_url_remove = Constant.icon_url_host + "delete.svg";
  public static icon_url_remove_table =
    Constant.icon_url_host + "delete-table.svg";
  public static icon_url_pencil = Constant.icon_url_host + "butchi.svg";
  public static icon_url_copy = Constant.icon_url_host + "copy.svg";
  public static icon_url_notice = Constant.icon_url_host + "notice-large.svg";
  public static icon_url_notice_bg = Constant.icon_url_host + "notice-bg.svg";
  public static icon_url_pay_ment = Constant.icon_url_host + "thanh-toan.svg";
  public static icon_url_files = Constant.icon_url_host + "files.svg";
  public static icon_url_files_active =
    Constant.icon_url_host + "files-active.svg";
  public static icon_url_user = Constant.icon_url_host + "user.svg";
  public static icon_url_user_id = Constant.icon_url_host + "iconID.svg";
  public static icon_url_language = Constant.icon_url_host + "ic-language.svg";
  public static icon_url_payment = Constant.icon_url_host + "payment.svg";
  public static icon_url_fail = Constant.icon_url_host + "error.svg";
  public static icon_url_fails = Constant.icon_url_host + "error.svg";
  public static icon_url_success = Constant.icon_url_host + "success-bg.svg";
  public static icon_url_white_success = Constant.icon_url_host + "success.svg";
  public static icon_url_reupload = Constant.icon_url_host + "Reuplpad.png";
  public static icon_url_close_modal = Constant.icon_url_host + "close-x.png";
  public static icon_url_minimisize = Constant.icon_url_host + "minisize.png";
  public static icon_url_toolchat = Constant.icon_url_host + "callme.png";
  public static icon_url_viewmore = Constant.icon_url_host + "viewmore.svg";
  public static icon_url_twitter = Constant.icon_url_host + "TWITTER.svg";
  public static icon_url_viber = Constant.icon_url_host + "VIBER.svg";
  public static icon_url_skype = Constant.icon_url_host + "SKYPE.svg";
  public static icon_url_messenger = Constant.icon_url_host + "MESSENGER.svg";
  public static icon_url_youtube = Constant.icon_url_host + "YOUTUBE.svg";
  public static icon_url_facebook = Constant.icon_url_host + "FACEBOOK.svg";
  public static icon_url_blog_fb = Constant.icon_url_host + "fb.svg";
  public static icon_url_blog_twt = Constant.icon_url_host + "twt.svg";
  public static icon_url_blog_yt = Constant.icon_url_host + "ytb.svg";
  public static icon_files = Constant.icon_url_host + "files.svg";
  public static icon_down = Constant.icon_url_host + "download.png";
  public static icon_user = Constant.icon_url_host + "user.svg";
  public static icon_check = Constant.icon_url_host + "check.svg";
  public static icon_sinhloi = Constant.icon_url_host + "sinhloi.svg";
  public static icon_robot = Constant.icon_url_host + "robot.svg";
  public static icon_antoan = Constant.icon_url_host + "antoan.svg";
  public static icon_mienphi = Constant.icon_url_host + "mienphi.svg";
  public static icon_url_fa_global = Constant.icon_url_host + "icon-global.svg";
  public static icon_url_fa_mail = Constant.icon_url_host + "icon-mail.svg";
  public static icon_url_fa_marker = Constant.icon_url_host + "icon-marker.svg";
  public static icon_url_fa_phone = Constant.icon_url_host + "icon-phone.svg";
  public static logo_blog_url_host = Constant.logo_url_host + "blog.png";
  public static logo_no_connect_internet =
    Constant.icon_503_host + "Disconnect.png";
  public static icon_host = Constant.icon_url_host;
  public static icon_url_loading = Constant.icon_host + "loading.svg";
  public static icon_url_close = Constant.icon_host + "close.png";
  public static icon_url_close_no_backround =
    Constant.icon_host + "close_no_backround.png";
  public static icon_url_warning = Constant.icon_host + "warning.svg";
  public static icon_url_warning_nbg = Constant.icon_host + "ic-warning.svg";
  public static icon_url_success_nbg = Constant.icon_host + "ic-success.svg";
  public static icon_url_logo = Constant.icon_host + "logo.png";
  public static icon_url_logo_w = Constant.icon_host + "logo-w.png";
  public static icon_picture_default = Constant.icon_host + "user.png";
  public static icon_url_send_mail_done = Constant.icon_host + "sendMail.svg";
  public static icon_url_upload = Constant.icon_url_host + "upload.png";
  public static icon_url_delete_btn = Constant.icon_url_host + "delete-btn.png";
  public static logo_url_logo_1 = Constant.logo_url_host + "logo.svg";
  public static logo_url_logo_2 = Constant.logo_url_host + "logo-blue.svg";
  public static logo_url_logo_3 = Constant.logo_url_host + "new-logo-blue.svg";
  public static logo_url_logo_white =
    Constant.logo_url_host + "fmarket-white.svg";
  public static logo_url_logo_blue =
    Constant.logo_url_host + "fmarket-blue.png";
  public static icon_url_logo_4 = Constant.logo_url_host + "logo4.png";
  public static icon_url_logo_5 = Constant.logo_url_host + "logo5.png";
  agents;
  public static icon_url_logo_6 = Constant.logo_url_host + "logo6.png";
  public static icon_url_logo_7 = Constant.logo_url_host + "logo7.png";
  public static share_logo = Constant.logo_url_host + "logo-share.png";
  public static logo_blue = Constant.logo_url_host + "logo_blue.png";

  //API
  // OTP
  public static api_security_otp_change_mail =
    "users/change-email-otp/step1/create";
  public static api_security_otp_confirm_change_mail =
    "users/change-email-otp/step1/confirm";
  public static api_security_otp_change_phone =
    "users/change-phone/step1/create";
  public static api_security_otp_confirm_phone_1 =
    "users/change-phone/step1/confirm";
  public static api_security_otp_confirm_phone_2 =
    "users/change-phone/step2/confirm";

  //Const
  public static const_refresh_select = "refresh";
  public static const_time_out_set_interval = 5;
  public static const_time_out_interval = 30 * 60;
  public static const_time_out_data_service = 60 * 1000;
  public static const_page_max_size = 1000;
  public static const_type_pdf = "PDF";
  public static const_type_excel = "XLS";
  public static const_pagerSize_default = 25;
  public static const_pagerSize_top_manager = 15;
  public static const_timeout = 300000;
  public static const_default_format_number = ".0-2";
  public static const_default_format_date = "dd/MM/yyyy";
  public static const_default_format_date_option = "dd/mm/yyyy";
  public static const_decimal_limit = 2;
  public static const_max_size_upload = 5 * 1024 * 1024;
  public static const_max_size_picture = 5 * 1024 * 1024;
  public static default_maxlenght = 254;
  public static default_maxlenght_about = 255;
  public static max_length = 40;
  public static max_length_name_product = 20;
  public static max_length_day = 17;
  public static min_length_password = 8;
  public static min_length_email = 4;
  public static max_length_description = 160;
  public static max_length_name = 70;
  public static max_length_nickName = 16;
  // Regex
  public static REGEX_EMAIL = "/^w+@([0-9a-zA-Z]+.?)+$/";

  public static DAYS_OF_WEEK_WORKING_DAY = [1, 2, 3, 4, 5];

  public static extension_img =
    "jpg|JPG|jpeg|JPEG|tiff|TIFF|png|PNG|bmp|BMP|bgp|BGP|PPM|ppm|PGM|pgm|pbm|PBM|ico|ICO|PGF|pgf|IMG|img";
  public static extension_file_doc =
    "pdf|PDF|xls|XLS|xlsx|XLSX|doc|DOC|docx|DOCX|zip|ZIP|rar|RAR";

  public static checkMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor);
    return window.screen.width < 768 ? check : false;
  }

  public static checkTablet() {
    let screen = window.screen.width;
    return screen >= 768 && screen <= 1024;
  }

  public static checkDateIssue(date) {
    let d = parseInt(date);
    let dayNow = new Date().getDate();
    let monthNow = new Date().getMonth() + 1;
    let yearNow = new Date().getFullYear();
    let day = new Date(d).getDate();
    let month = new Date(d).getMonth() + 1;
    let year = new Date(d).getFullYear();
    if (yearNow === year) {
      if (monthNow === month) {
        return dayNow <= day;
      } else return monthNow <= month;
    } else {
      return yearNow <= year;
    }
  }

  public static checkAndroid() {
    let userAgent = navigator.userAgent || navigator.vendor;
    return /android/i.test(userAgent);
  }

  public static checkIOS() {
    let userAgent = navigator.userAgent || navigator.vendor;
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
    }
  }

  public static getInputAcceptDocFile() {
    return Constant.extension_file_doc
      .split("|")
      .map((item) => "." + item)
      .join(",");
  }

  public static getInputAcceptImgFile() {
    return Constant.extension_img
      .split("|")
      .map((item) => "." + item)
      .join(",");
  }

  public static arraysEqual(a, b) {
    a = Array.isArray(a) ? a : [];
    b = Array.isArray(b) ? b : [];
    return a.length === b.length && a.every((el, ix) => el === b[ix]);
  }
}
